<template>


  <transition name="slide">

  <div class="view-trip">
   <main-nav />
    <main-titlebar myclass="purpleBar" :showback="showBack"/>
    <div class="trip-view-wrapper">
         <div v-for="trip in trips" :key="trip.id" >
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-clock"></i>  {{ formatDateTime(trip.etd) }}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.origin_address}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.destination_address}}</div>
            </div>
            <div class="trip-view-row">
              <div class="trip-view-col2"><i class="fa-solid fa-people-group"></i> {{trip.passengers}}</div>
              <div class="trip-view-col2">  {{ formatCurrency(trip.price)}}</div>
            </div>

            <Form @submit="offerTrip" :validation-schema="schema">
          
            <Field name="id" id="id" v-model="trip.id"  type="hidden"/>
       

            <!-- Commission -->
            <div class="form-group">
              <div class="col-2  col-2-c">
                <div class="form-label">
                  <label for="commission">Commissie %</label>
                </div>
              </div>
              <div class="col-2  col-2-c">
                <Field name="commission" id="commission" class="form-input" v-model="commission"  type="number" placeholder=""/>
              </div>
            </div>
            <div class="form-group">
              <ErrorMessage name="commission" class="error-feedback" />
            </div>

            <!-- Circle -->
            <div class="form-group">
              <div class="col-2  col-2-c">
                <div class="form-label">
                  <label for="circle">Circel</label>
                </div>
              </div>
              <div class="col-2  col-2-c">
                <Field name="circle" as="select" class="form-input" >
                  <option value="" selected>Kies een circel</option>
                     <option v-for="circle in myCircles" :key="circle.id" :value="circle.id">{{circle.name}}</option>
                  <option value="0">Publiek</option>
                </Field>
              </div>
            </div>
            <div class="form-group">
              <ErrorMessage name="circle" class="error-feedback" />
            </div>


            <!-- Phone passenger -->
            <div class="form-group">
              <div class="col-2  col-2-c">
                <div class="form-label">
                  <label for="commission">Telefoon</label>
                </div>
              </div>
              <div class="col-2  col-2-c">
                <Field name="phone" id="phone" class="form-input" v-model="phone"  type="number" placeholder="Telefoon passagier"/>
              </div>
            </div>

            <!-- Controls -->

            <div class="trip-view-row">
              <div class="trip-view-col">
                <button class="default-button" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  <span>Rit aanbieden</span>
                </button>
              </div>
            </div>


           

          </Form>
         
          </div>
    </div>
  </div>

    </transition>

</template>
<script>

import TripService from "../../services/trip-service";
import CircleService from "../../services/circle-service";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name:'settings',
  data() {
    var status = 'loading'
    var trips;
    var commission;
    var markers = [];
    var myCircles = [];
    var phone = "";
    var showBack = true;
    const schema = yup.object().shape({
      commission: yup.string().required("Commissie is verplicht"),
      circle: yup.string().required("Circel is verplicht"),
      // destination_address: yup.string().required("Destination is required")
    });
    return { 
      trips,
      myCircles, 
      loading: false,
      message: "",
      status, 
      markers,
      schema, 
      commission,
      phone,
      showBack }
  },
  methods: {
    offerTrip(trip) {
      // return id;
      TripService.offerTrip(trip).then(
        (response) => {
          this.status = response.status;
          // this.trips = response.data;
          console.log("Trip offered to pool: " + JSON.stringify(this.trips));
          this.$router.push('/trips/open');        
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
  
    }
    
  },
  mounted() {
    var targ = this;
    console.log("this.id: " + this.$route.params.id );
    TripService.getTrip(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;
        targ.trips = response.data;
        console.log("data loaded from: " + JSON.stringify(targ.trips));
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

     CircleService.getMyCircles().then(
      (response) => {
        console.log("CircleService.getMyCircles: data loaded from: " + JSON.stringify(response));
        targ.status = response.status;
        targ.myCircles = response.data;
      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

  },
  computed: {
    isPublic() {
      return this.trips[0].public;
    },
    isOwner() {
      var r = false;
      var tripOwner = this.trips[0]["owner"]
      var user = JSON.parse(localStorage.getItem('user')).userid;
      // var user  = this.$store.state.auth.user.userid;
      console.log("isOwner: " + tripOwner + " | userid:" + user);
      if(user == tripOwner) {
        r = true;
      }
      return r;
    },
  },
  
  components: {
    Form,
    Field,
    ErrorMessage,
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,
  }
}
</script>